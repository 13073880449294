<template>
  <b-card>
    <b-card-body class="p-0 w-100">
      <b-tabs content-class="mt-2" lazy>
        <b-tab title="Notes"
               @click="changeTab('notes')">
          <b-button
            class="mt-1 d-none"
            variant="primary"
            size="sm">
            Edit Notes
          </b-button>
        </b-tab>
        <b-tab title="Social"
               @click="changeTab('social')">
          <b-button
            class="mt-1 d-none"
            variant="primary"
            size="sm">
            Edit Links
          </b-button>
        </b-tab>
        <template #tabs-end>
          <b-nav-item
            class="ml-auto"
            role="presentation"
            @click.prevent="openModal"
          >
            <b-button
              variant="primary"
              size="sm">
              <feather-icon icon="PlusIcon"/>
              <span v-if="showingTab === 'notes'">Note</span>
              <span v-else>Social</span>
            </b-button>
          </b-nav-item>
        </template>
      </b-tabs>

      <student-note-item v-if="showNotes" :notes="profileData.notes" :student-data="studentData" :profile-data="profileData" ref="notesRef" />
      <span v-else-if="!showNotes && showingTab === 'notes'">No notes created for this student, yet.</span>

      <student-social-item v-if="showSocial" :social-links="profileData.socialLinks" :student-data="studentData" :profile-data="profileData" ref="socialLinksRef" />
      <span v-else-if="!showSocial && showingTab === 'social'">No social links created for this student, yet.</span>

      <b-modal
        id="modal-center"
        v-model="openCreateModal"
        centered
        size="lg"
        :title-html="modalTitle"
        ok-only
        @ok="closeModal"
        :ok-title="'Save ' + (showingTab === 'notes' ? 'Note' : 'Social Link') + '/s'"
      >
        <b-card-text>
          <student-create-tab-notes ref="crudNotes" v-show="showingTab === 'notes'" :student-data="studentData" :profile-data="profileData"/>
          <student-create-tab-social-links ref="crudSocial" v-show="showingTab === 'social'" :student-data="studentData" :profile-data="profileData"/>
        </b-card-text>
      </b-modal>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCol, BCard, BImg, BMedia, BAvatar, BCardBody, BTabs, BTab, BButton, BNavItem, BModal, VBModal, BCardText } from 'bootstrap-vue';
import StudentNoteItem from './components/StudentNoteItem';
import StudentSocialItem from './components/StudentSocialItem';
import StudentCreateTabNotes from "@/views/apps/student/students-edit/tabs/StudentCreateTabNotes.vue";
import StudentCreateTabSocialLinks from "@/views/apps/student/students-edit/tabs/StudentCreateTabSocialLinks.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    StudentCreateTabNotes,
    StudentCreateTabSocialLinks,
    BCol,
    BCard,
    BCardBody,
    BModal,
    BTabs,
    BTab,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    BNavItem,
    StudentNoteItem,
    StudentSocialItem,
    BCardText,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      showingTab: 'notes',
      openCreateModal: false,
      studentDataList: this.studentData,
    };
  },
  methods: {
    closeModal() {
      if(this.showingTab === 'notes') {
        this.$refs.crudNotes.doSaveChanges();
        this.$refs.notesRef.reloadNotes();
      } else {
        this.$refs.crudSocial.doSaveChanges();
      }
    },
    changeTab(tab) {
      this.showingTab = tab;
    },
    openModal() {
      this.openCreateModal = true;
    },
  },
  computed: {
    showNotes() {
      return this.showingTab === 'notes' && (this.profileData && this.profileData.notes && this.profileData.notes.length);
    },
    showSocial() {
      return this.showingTab === 'social' && (this.profileData && this.profileData.socialLinks && this.profileData.socialLinks.length);
    },
    modalTitle() {
      return '<i class=\'feather icon-file-plus\'></i> Create ' + (this.showingTab === 'notes' ? 'Note' : 'Social Link')
    }
  }
};
</script>

<style></style>
