<template>
  <component is='div'>
    <b-modal
      id='modal-lg'
      ok-title='Add to Session'
      centered
      size='llg'
      :title='`Search for ${stage}s`'
      ref='modal'
      @hidden="() => $emit('update:is-add-session-open', false)"
      :visible='isAddSessionOpen'
      :hide-footer='true'
    >
      <b-card no-body>
        <b-row>
          <b-col cols='12' lg='6'>
            <b-pagination
              v-model='currentPage'
              :total-rows='totalResults'
              :per-page='perPage'
              aria-controls='table-test'
            ></b-pagination>
          </b-col>

          <b-col cols='12' md='6'>
            <b-form-group v-if="stage === 'session'" class='justify-content-end'>
              <div class='d-flex align-items-center'>
                <b-form-checkbox id='restricted-search' v-model='restricted' name='restricted-search' class='mr-3'>
                  Restricted
                </b-form-checkbox>

                <label class='mr-1'>Search</label>
                <b-form-input
                  v-model='searchTerm'
                  placeholder='Search'
                  type='text'
                  class='d-inline-block'
                  @input='handleSearch'
                />
              </div>
            </b-form-group>
            <div v-else class='d-flex justify-content-end'>
              <b-button variant='primary' :to='createAndReturn'> Create Profile</b-button>
            </div>
          </b-col>
        </b-row>

        <b-table
          id='table-test'
          ref='refSessionTable'
          v-if="stage === 'session'"
          :items='sessionProvider'
          :fields='sessionFields'
          :per-page='perPage'
          :current-page='currentPage'
          :filter='searchTerm'
          :sort-by.sync='sortBy'
          :sort-desc.sync='sortDesc'
          :responsive='true'
        >
          <template #cell(status)='data'>
            {{ title(data.item.status) }}
          </template>

          <template #cell(startDate)='data'>
            {{ data.item.startDate ? moment(data.item.startDate).utc().format('l') : 'n/a' }}
          </template>

          <template #cell(visibility)='data'>
            {{ title(data.item.visibility) }}
          </template>

          <template #cell(actions)='data'>
            <b-button :disabled='isBusy' @click='isTransfer ? openProvideReason(data.item) : assignSession(data.item)' variant='primary' size='sm'>Select
            </b-button>
          </template>
        </b-table>

        <b-table
          v-if="!isTransfer && stage === 'profile'"
          id='table-test'
          :items='profileProvider'
          :fields='profileFields'
          :per-page='perPage'
          :current-page='currentPage'
          :filter='searchTerm'
          :responsive='true'
        >
          <template #cell(funding)='data'>
            {{ title(data.item.attributes.fundingSource) }}
          </template>

          <template #cell(pathway)='data'>
            {{ data.item.pathway ? title(data.item.pathway.name) : 'n/a' }}
          </template>

          <template #cell(subject)='data'>
            {{ data.item.subject ? title(data.item.subject.name) : 'n/a' }}
          </template>

          <template #cell(pathwayDate)='data'>
            {{ data.item.pathwayDate ? moment(data.item.pathwayDate).utc().format('l') : 'n/a' }}
          </template>

          <template #cell(createdAt)='data'>
            {{ moment(data.item.createdAt).utc().format('l') }}
          </template>

          <template #cell(status)='data'>
            {{ title(data.item.status) }}
          </template>

          <template #cell(actions)='data'>
            <b-button :disabled='isBusy' @click='selectProfile(data.item)' variant='primary' size='sm'>Select</b-button>
          </template>
        </b-table>
      </b-card>
    </b-modal>

    <b-modal
      id='modal-reason'
      ok-title='Reassign'
      centered
      size='lg'
      title='Provide a Reason for Reassignment'
      ref='modal'
      @hidden="() => $emit('update:is-transfer-open', false)"
      :visible='isTransferOpen'
      @ok='assignSession'
    >
      <b-card no-body>
        <b-row>
          <b-col>

            <b-form-group label='Reason' label-for='transfer-reason'>
              <b-form-input
                id='transfer-reason'
                v-model='transferReason'
                autofocus
                :state='transferReason && transferReason.length > 0'
                trim
                placeholder=''
              />
            </b-form-group>

          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </component>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { ref } from '@vue/composition-api';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 1,
      totalResults: 0,
      isTransfer: false,
      isTransferOpen: false,
      transferReason: '',

      sessionFields: [
        { key: 'actions' },
        { key: 'name', sortable: true },
        { key: 'startDate', sortable: true, label: 'Start' },
        { key: 'status', sortable: true }
      ],
      profileFields: [
        { key: 'actions' },
        { key: 'pathway' },
        // {key: 'subject'}, // Todo: Renable
        { key: 'funding' },
        { key: 'pathwayDate', sortable: true, label: 'Start' },
        { key: 'createdAt', sortable: true, label: 'Created' },
        { key: 'status', sortable: true },
      ],

      stage: 'profile',
      profile: undefined,
      session: undefined,

      sortBy: 'startDate',
      sortDesc: true,

      restricted: true,

      createAndReturn: {
        name: 'apps-roster-profile-list',
        query: {
          op: 'create',
          return: 'apps-students-view',
          studentId: this.studentData ? this.studentData.id : undefined,
        },
      },
    };
  },
  props: {
    isAddSessionOpen: {
      type: Boolean
    },
    studentData: {
      type: Object,
      default: () => {},
    },
    profileData: {
      type: Object,
      default: () => {},
    },
    overridePathway: {
      type: String
    },
    overrideSubject: {
      type: String
    }
  },
  emits: ['refresh'],
  watch: {
    isAddSessionOpen(val) {
      if (val) {
        this.stage = 'profile';
        this.profile = undefined;
      }
    },
    restricted(val) {
      this.$refs.refSessionTable.refresh();
    },
  },
  methods: {
    sessionProvider(ctx) {
      const { pathway, subject } = this.profile;

      let adl = {};
      if (this.overridePathway) {
        adl.pathway = this.overridePathway;
      } else if (this.overrideSubject) {
        adl.subject = this.overrideSubject;
      } else if (pathway) {
        adl.pathway = (pathway.id || pathway);
      } else if (subject) {
        adl.subject = (subject.id || subject);
      }

      if (!this.restricted) {
        delete adl.pathway;
        delete adl.subject;
      }

      const promise = store.dispatch('app-roster/fetchSessions', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy ? ctx.sortBy : 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,

        // populate: 'student',
        ...adl,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    profileProvider(ctx) {
      if (this.isTransfer) {
        console.warn(`> attempting to search for profile even though action is a transfer`);
        return;
      }

      const promise = store.dispatch('app-roster/fetchProfiles', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy ? ctx.sortBy : 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,

        student: this.studentData.id,
        session: 'null'
        // populate: 'student',
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.totalResults = totalResults;

          if (results.length === 0) {
            console.log(`create + return`, this.createAndReturn);
            this.$router.push(this.createAndReturn);
          }

          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          });

          return [];
        });
    },
    openProvideReason(session) {
      this.isBusy = true;
      this.session = session;
      this.transferReason = '';
      this.isTransferOpen = true;
    },
    assignSession(session) {
      if (!this.session && session) {
        this.session = session;
      }

      (
        this.isTransfer ?
        store
          .dispatch('app-roster/transferStudentToSession', {
            sessionId: this.session.id,
            profileId: this.profile.id,
            reassignReason: `${this.transferReason} - Reassigned to ${this.session.name}`
          }) :
        store
          .dispatch('app-roster/addStudentsToSession', {
            sessionId: this.session.id,
            profileIds: [this.profile.id]
          })
      ).then((response) => {
        const { data: session } = response;

        if (!session.profiles.includes(this.profile.id)) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'XIcon',
              variant: 'error',

              title: 'Failed to add to session',
              text: 'This student is already assigned to this session'
            }
          });
        }

        this.$emit('update:is-add-session-open', false);
        this.$emit('refresh', true);
        this.stage = 'session';

        return this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'EditIcon',
            variant: 'success',

            title: 'Added profile to session',
            text: 'This student has been assigned to this session'
          }
        });
      })
      .catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to assign profile',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.response ? error.response.data.message : error.message,
          },
        });
      });

    },
    selectProfile(profile) {
      this.profile = profile;
      this.stage = 'session';
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    }
  },
  mounted() {
    if (this.profileData && this.profileData.id) {
      this.profile = this.profileData;
      this.stage = 'session';
      this.isTransfer = true;
    } else {
      this.isTransfer = false;
    }
  },
  setup({ props }) {
    const sessionData = ref(null);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.modal .modal-llg {
  min-width: 900px !important;
  width: 100% !important;
}
</style>

