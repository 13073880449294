<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <div>
        <feather-icon icon="StudentIcon" size="19" />
        <h4 class="mb-0" style="padding-top: 5px;">{{ note.note.title }}</h4>
      </div>

      <div class="ml-auto">
        <a class="btn-sm text-primary btn-block user-select-none" @click="deletePrompt = !deletePrompt">
          <feather-icon icon="XIcon" class="mr-25" />
          <span>Delete?</span>
        </a>

        <div v-show="deletePrompt" class="ml-auto" style="position: absolute; width: 280px; right: 80px; top: 10px; text-align: right; padding-right: 32px;">
          <small>Are you sure? &nbsp;</small>
          <b-button-group size="sm" @click="deletePrompt = false">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-sm" variant="primary" @click="removeItem">
              Yes
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-sm" variant="outline-primary">
              No
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <hr>

    <!-- Form: Personal Info Form -->
    <b-form ref="form" @submit.prevent="false">
      <!-- Row Loop -->
      <b-row ref="row">
        <!-- Item Name -->
        <b-col lg="12" md="12" sm="12">
          <div class="row">
            <div class="col-8">
              <b-form-group label="Name" :label-for="`note-title-1`">
                <b-form-input :id="`note-title-1`" type="text" placeholder="Note Title" v-model="note.note.title" />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Visibility" class="mb-0" :label-for="`note-visibility-1`">
                <b-form-radio-group
                  :id="`note-visibility-1`"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="note.note.visibility"
                  :options="visibilityOptions"
                  class="w-100 mt-md-1"
                  value-field="value"
                  text-field="label"
                />
              </b-form-group>
            </div>
          </div>
        </b-col>

        <!-- Description -->
        <b-col lg="12" md="12" sm="12">
          <b-form-group label="Description" :label-for="`note-description-1`">
            <b-form-textarea
              :id="`note-description-1`"
              type="text"
              placeholder="Description"
              v-model="note.note.description"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="float-left text-muted">
        <small>
          Created by {{ note.note.author.name }} at {{ moment(note.note.createdAt).utc().format('L hh:mm A') }}.
        </small>
      </div>
      <div class="float-right text-muted">
        <small v-if="note.note.updatedAt">
          Last updated at {{ moment(note.note.updatedAt).utc().format('L hh:mm A') }}.
        </small>
      </div>
      <div class="clearfix"></div>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BButtonGroup,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudentsList from '../../students-list/useStudentsList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '../../studentStoreModule';
import router from "@/router";
import moment from 'moment';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BButtonGroup,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      notes: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      deletePrompt: false,
    };
  },
  methods: {
    doSaveChanges(cb) {
      const pwd = this.studentData;
      const id = pwd.id;

      const note = JSON.parse(JSON.stringify(this.note.note));

      if(Object.hasOwn(note, '_showDetails')) {
        delete note['_showDetails'];
      }

      const copy = JSON.parse(JSON.stringify(this.notes));
      copy[this.note.index] = note;

      const parsed = copy.filter((adr) => adr.title && adr.description)
        .map((cert) => {
          return {
            title: cert.title,
            description: cert.description,
            visibility: cert.visibility,
            author: this.userData.id || cert.author || undefined,
            _id: cert._id,
          };
        });

      store
        .dispatch('app-student/updateStudentProfile', {
          id,
          profileData: { notes: parsed },
        })
        .then((response) => {
          this.loadStudent();

          if(typeof cb === 'function') {
            cb();
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    removeItem() {
      if(this.notes[this.note.index] === undefined) return;
      this.notes.splice(this.note.index, 1);

      this.doSaveChanges(() => this.$emit('close-modal'));
    },
    loadStudent(json) {
      if (json && json.notes) {
        this.notes = json.notes;
        return;
      }

      this.$store
        .dispatch('app-student/fetchStudentProfile', {
          id: this.studentDataInfo.id,
          // queryParams: {cb: Date.now()}
        })
        .then((response) => {
          const json = response.data;

          /*this.notes = json.notes;*/

          this.notes = json.notes;

          if (!this.notes) this.notes = [];
        });
    },
  },
  mounted() {
    this.loadStudent();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
        setTimeout(() => {
          store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
        }, 3000);
      }
    });

    const { statusOptions, visibilityOptions } = useStudentsList();

    return {
      studentDataInfo,
      statusOptions,
      visibilityOptions,
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
