<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <div>
        <feather-icon icon="StudentIcon" size="19" />
        <h4 class="mb-0" style="padding-top: 5px;">Notes</h4>
      </div>

      <div class="ml-auto">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-sm" @click="repeatAgain">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
      </div>
    </div>
    <hr>

    <!-- Form: Personal Info Form -->
    <b-form ref="form" @submit.prevent="repeatAgain">
      <h5 v-if="!unpreloadedLists || unpreloadedLists.length === 0" class="text-center">No notes listed</h5>

      <!-- Row Loop -->
      <b-row v-else v-for="(item, index) in notes" :id="item.id" :key="item.id" ref="row">
        <!-- Item Name -->
        <b-col lg="12" md="12" sm="12" v-if="item.preloaded === false">
          <div class="row">
            <div class="col-7">
              <b-form-group label="Name" :label-for="`note-title-${index}`">
                <b-form-input :id="`note-title-${index}`" type="text" placeholder="Note Title" v-model="item.title" />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Visibility" class="mb-0" :label-for="`note-visibility-${index}`">
                <b-form-radio-group
                  :id="`note-visibility-${index}`"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="item.visibility"
                  :options="visibilityOptions"
                  class="w-100 mt-md-1"
                  value-field="value"
                  text-field="label"
                />
              </b-form-group>
            </div>
            <div class="col-1">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                style="padding-left: 7px; padding-right: 4px;"
                class="mt-0 mt-md-2 btn-sm"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
            </div>
          </div>
        </b-col>

        <!-- Description -->
        <b-col lg="12" md="12" sm="12" v-if="item.preloaded === false">
          <b-form-group label="Description" :label-for="`note-description-${index}`">
            <b-form-textarea
              :id="`note-description-${index}`"
              type="text"
              placeholder="Description"
              v-model="item.description"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="item.preloaded === false">
          <hr />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudentsList from '../../students-list/useStudentsList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '../../studentStoreModule';
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      submit: { sync: false },

      notes: [],
      nextTodoId: 1,
      userData: JSON.parse(localStorage.getItem('userData')),
    };
  },
  computed: {
    unpreloadedLists() {
      return this.notes.filter(note => note.preloaded === false);
    }
  },
  methods: {
    doSaveChanges() {
      const pwd = this.studentData;
      const id = pwd.id;

      const parsed = this.notes
        .filter((adr) => adr.title && adr.description)
        .map((cert) => {
          return {
            title: cert.title,
            description: cert.description,
            visibility: cert.visibility,
            author: cert.author || this.userData.id || undefined,
          };
        });

      store
        .dispatch('app-student/updateStudentProfile', {
          id,
          profileData: { notes: parsed },
        })
        .then((response) => {
          this.loadStudent();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    repeatAgain() {
      this.notes.push({
        preloaded: false,
        visibility: 'internal',
      });
    },
    removeItem(index) {
      const note = this.notes[index];

      if(note === undefined) return;
      if(note.preloaded === true) return;

      this.notes.splice(index, 1);
    },
    loadStudent(cb) {
      this.$store
        .dispatch('app-student/fetchStudentProfile', {
          id: this.studentDataInfo.id,
          // queryParams: {cb: Date.now()}
        })
        .then((response) => {
          const json = response.data;

          /*this.notes = json.notes;*/

          this.notes = json.notes.map(note => {
            return { ...note, preloaded: true };
          });

          if (!this.notes) this.notes = [];

          if(typeof cb === 'function') {
            cb();
          }
        });
    },
  },
  mounted() {
    this.loadStudent(() => this.repeatAgain());
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
        setTimeout(() => {
          store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
        }, 3000);
      }
    });

    const { statusOptions, visibilityOptions } = useStudentsList();

    return {
      studentDataInfo,
      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
