<template>
  <div>
    <b-row>
      <b-table :items="loadedNotes" :fields="fields">
        <template #row-details="row">
          <div class="row">
            <div class="col-12">
              <b-card style="background-color: rgba(0,0,0, 0.5); border-radius: 0">
                <div class="float-left">
                  <h6 class="mb-0">{{ row.item.title }}</h6>
                </div>
                <div class="float-right">
                  <a class="text-success" @click="editNote(row.item, row.index)"><i class="feather icon-edit-1"></i></a>
                  &nbsp;
                  <a class="text-danger" @click="deleteNote(row.index)"><i class="feather icon-trash"></i></a>
                </div>
                <div class="clearfix"></div>
                <hr style="margin: 10px 0">

                <p class="mt-0">{{ row.item.description }}</p>
                <small>
                  &bull; {{ row.item.author.name }} at {{ moment(row.item.createdAt).utc().format('L hh:mm A') }}
                </small>
                <small v-if="row.item.updatedAt">
                  <br>&bull; updated at {{ moment(row.item.updatedAt).utc().format('L hh:mm A') }}
                </small>
              </b-card>
            </div>
          </div>
        </template>

        <template #cell(title)="data">
          <div class="float-left">
            <b-avatar
              size="24"
              :src="data.item.author.avatar"
              :text="avatarText(data.item.author.name)"
              variant="primary"
              :to="{ name: 'apps-users-view', params: { id: data.item.author.id } }"
              v-b-tooltip.hover
              :title="data.item.author.name"
            />
          </div>
          <div class="float-left ml-1" style="padding-top: 0;" role="button" v-model="data.detailsShowing" @click.prevent="data.toggleDetails">
            <span class="d-inline-block text-danger text-truncate" style="max-width: 4rem;">
              {{ data.value }}
            </span>
          </div>
          <div class="clearfix"></div>
        </template>
        <template #cell(description)="data">
          <div role="button" v-model="data.detailsShowing" @click.prevent="data.toggleDetails">
            <span class="user-select-none d-inline-block text-truncate" style="max-width: 15rem;">
              {{ data.value }}
            </span>
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div role="button" v-model="data.detailsShowing" @click.prevent="data.toggleDetails">
            {{ data.value ? moment(data.value).format('L') : 'unknown' }}
          </div>
        </template>
      </b-table>
    </b-row>

    <b-modal
      id="modal-center"
      v-model="openEditModal"
      centered
      size="lg"
      :title-html="modalTitle"
      ok-only
      @ok="closeModal"
      :ok-title="'Save Note'"
    >
      <b-card-text>
        <student-edit-tab-notes ref="crudEditNotes" :note="selectedNote" @close-modal="closeModalImmediate" :student-data="studentData" :profile-data="profileData"/>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
  BAvatar,
  BMedia,
  VBTooltipPlugin,
  BModal,
  BCardText,
} from "bootstrap-vue";
import moment from 'moment';
import { avatarText } from '@core/utils/filter';
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted } from "@vue/composition-api";
import studentStoreModule from '../../studentStoreModule';
import StudentEditTabNotes from "@/views/apps/student/students-edit/tabs/StudentEditTabNotes.vue";

export default {
  name: 'ProfileNoteItem',
  components: {
    StudentEditTabNotes,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
    BAvatar,
    BMedia,
    BModal,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltipPlugin,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
    notes: {
      type: Array,
    },
  },
  computed: {
    modalTitle() {
      const subtitle = this.selectedNote && this.selectedNote.note && this.selectedNote.note.title ? (': ' + this.selectedNote.note.title) : '';
      return '<i class=\'feather icon-edit\'></i> Edit Note' + subtitle;
    }
  },
  data: () => {
    return {
      loadedNotes: [],
      fields: [
        {
          key: 'title',
          label: 'Title',
          thStyle: { width: '30%' },
        },
        {
          key: 'description',
          label: 'Description',
          thStyle: { width: '50%' },
        },
        {
          key: 'createdAt',
          label: 'Created',
          thStyle: { width: '10%' },
        },
      ],
      openEditModal: false,
      selectedNote: null,
    };
  },
  mounted() {
    this.loadedNotes = this.notes;
  },
  methods: {
    closeModalImmediate() {
      this.openEditModal = false;
    },
    closeModal() {
      this.$refs.crudEditNotes.doSaveChanges();
    },
    editNote(note, index) {
      this.selectedNote = { note: JSON.parse(JSON.stringify(note)), index };
      this.openEditModal = true;
    },
    deleteNote(index) {
      const notes = this.loadedNotes;
      const note = notes[index];

      if(note === undefined) return;

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to permanently delete this note?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        confirmButtonText: 'Delete',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadedNotes.splice(index, 1);

          const parsed = this.loadedNotes
            .filter((adr) => adr.title && adr.description)
            .map((cert) => {
              return {
                title: cert.title,
                description: cert.description,
                visibility: cert.visibility,
                author: cert.author || this.userData.id || undefined,
              };
            });

          store
            .dispatch('app-student/updateStudentProfile', {
              id: this.studentData.id,
              profileData: { notes: parsed },
            })
            .then((response) => {
              this.reloadNotes();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Note deleted!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to edit student',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response ? error.response.data.message : error.message,
                },
              });
            });
        }
      });
      // notes.splice(index, 1);

      console.log(notes);
    },
    reloadNotes() {
      this.$store
        .dispatch('app-student/fetchStudentProfile', {
          id: this.studentData.id,
          // queryParams: {cb: Date.now()}
        })
        .then((response) => {
          const json = response.data;

          this.loadedNotes = json.notes;

          if (!this.loadedNotes) this.loadedNotes = [];
        });
    }
  },
  setup(props) {
    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    return {
      moment,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
